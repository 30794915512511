import React from "react";
import SEO from "../components/SEO";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";
import { rhythm } from "../utils/typography";
import Layout from "../components/layout";
import Img from "gatsby-image";
import Card from "react-bootstrap/Card";

export default ({ data }) => {
  return (
    <Layout>
      <div>
        <Helmet>
          <style type="text/css">
            {`
          .card {
            font-family: 'Playfair Display', serif;
            width: 100%;
            margin: 0;
            padding: 0;
            border: none;
            border-bottom: 1px solid rgba(0,0,0,.125);
          }
    `}
          </style>
          <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
        </Helmet>
        <SEO
          title="Home"
          keywords={[
            `southern magazine`,
            `the south`,
            `the double dealer`,
            `double`,
            `dealer`,
            `double dealer`,
            `new orleans`,
            `double dealer new orleans`,
            `double dealer magazine`,
            `the double dealer magazine`
          ]}
        />
        <div>
          <h6
            css={css`
              text-align: center;
              color: inherit;
              margin: 0;
              padding: 0;
            `}
          >
            The
          </h6>
          <h1
            css={css`
              border-bottom: 1px solid;
              text-align: center;
              color: inherit;
            `}
          >
            Double Dealer
          </h1>
        </div>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <Card bsPrefix="card">
              <Card.Body>
                <Link
                  to={node.fields.slug}
                  css={css`
                    text-decoration: none;
                    color: inherit;
                    &:hover {
                      text-decoration: underline;
                      color: #0080c0;
                    }
                  `}
                >
                  <Img fluid={node.frontmatter.cover_image.childImageSharp.fluid} />
                  <Card.Title>
                    <h2
                      css={css`
                        margin-top: ${rhythm(1 / 4)};
                        margin-bottom: ${rhythm(1 / 4)};
                        text-align: center;
                      `}
                    >
                      {node.frontmatter.title}
                    </h2>
                  </Card.Title>
                </Link>
                <Card.Subtitle className="mb-2 text-muted text-center">
                  {node.frontmatter.writer}
                </Card.Subtitle>
                <Card.Text>{node.excerpt}</Card.Text>
                <Card.Subtitle className="mb-2 text-muted text-center">
                  {node.frontmatter.date}
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 150)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            writer
            cover_image {
              childImageSharp {
                fluid(maxHeight: 560) {
                  ...GatsbyImageSharpFluid
                  src
                }
                resize(width: 1200) {
                  width
                  height
                  src
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;